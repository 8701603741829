import React from 'react';
import LogoImg from "../assets/landing/IncentiveHeroes_logo_SVG.svg";
import {NavLink} from "react-router-dom";
import IcoFb from "../assets/landing/facebook.svg";
import IcoIn from "../assets/landing/linkedin.svg";
import IcoTt from "../assets/landing/twitter.svg";
import IcoIs from "../assets/landing/instagram.svg";
import WagaLogoImg from "../assets/landing/WagabundoTravel_Logo_top.svg";
import PropTypes from 'prop-types';
import GoldenImg from "../assets/landing/award_goldenarrow.png";
import MpImg from "../assets/landing/award_mppower.png";

class Footer extends React.Component
{
    render()
    {
        return <footer className={ this.props.sticky ? 'd-none d-md-block fixed-bottom position-sticky' : null}>
            { this.props.jump ? (
                <div className="jumper">
                    <button type="button" className="text-primary" onClick={ this.props.jumpHandle }>
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" viewBox="0 0 48 48">
                            <circle cx="24" cy="24" r="24" fill="#fb0768" stroke="none"/>
                            <path strokeWidth="3.2" d="M18 21.33l6 6 6-6"/>
                        </svg>
                    </button>
                </div>
            ) : null }
            { this.props.awards ? (
                <div className="container-fluid awards px-1 d-xl-flex justify-content-center justify-content-md-end d-none d-xl-block">
                    <img src={ GoldenImg } alt="Golder Award"/>
                    <img className="mx-3" src={ MpImg } alt="MP Power Award"/>
                </div>
            ) : null }
            <section className="section1">
                <div className="container d-flex justify-content-between align-items-center">
                    &copy; INCENTIVE-HEROES
                    <a href="https://crm.incentive-heroes.com" className="btn btn-primary dropdown-toggle d-flex justify-content-between"><span>Lang: <em>Polski</em></span></a>
                </div>
            </section>
            { !this.props.jump ? (
            <section className="section2">
                <div className="container d-flex justify-content-around justify-content-md-between">
                    <div className="ih flex-column justify-content-between d-none d-md-flex">
                        <a href="https://www.incentive-heroes.com/en/"><img src={ LogoImg } className="img-fluid" alt="Incentive Heroes"/></a>
                        <div>
                            <div>INCENTIVE HEROES© - Partner program</div>
                            <div>WAGABUNDO TRAVEL™ - Sales Support</div>
                        </div>
                    </div>

                    <div className="menu flex-grow-1 d-none d-md-block">
                        <NavLink to="/about">About</NavLink>
                        <span className="bullet"/>
                        <NavLink to="/privacy">Privacy policy</NavLink>
                        <span className="bullet"/>
                        <NavLink to="/contact">Contact</NavLink>
                    </div>

                    <div className="social d-flex flex-column justify-content-between">
                        <div className="icons">
                            <a href="https://www.facebook.com/WagabundoTravel"><img src={ IcoFb } alt="Facebook"/></a>
                            <a href="https://www.linkedin.com/company/wagabundo-adventure-travel-&-incentive"><img src={ IcoIn } alt="LinkedIn"/></a>
                            <a href="https://twitter.com/wagabundotravel"><img src={ IcoTt } alt="Twitter"/></a>
                            <a href="https://www.instagram.com/wagabundotravel/"><img src={ IcoIs } alt="Instagram"/></a>
                        </div>
                        <div className="impl">
                            Created by:
                            <a href="https://www.wagabundotravel.com"><img src={ WagaLogoImg } alt="Wagabundo Travel"/></a>
                        </div>
                    </div>
                </div>
            </section>
                ) : null }
        </footer>;
    }
}

Footer.propTypes = {
    sticky: PropTypes.bool,
    jump: PropTypes.bool,
    jumpHandle: PropTypes.func,
    awards: PropTypes.bool
};

Footer.defaultProps = {
    sticky: false,
    jump: false,
    awards: false
};

export default Footer;
