import React from 'react';
import LoginDialog from "../logindialog";

export default function(props)
{
    return <main>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-10 col-md-6 col-lg-4 mx-auto">
                    <LoginDialog onSubmit={ () => alert("This is a DEMO app.") } onRememberPass={ () => false }/>
                </div>
            </div>
        </div>
    </main>;
}
