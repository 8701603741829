import { setLocale } from 'yup';

const YupLocale = {
    mixed: {
        required: 'Required field.'
    },
    string: {
        email: 'Please provide a valid email address.',
        min: `Minimum \${min} characters`
    }
};

export default {
    setLocale,
    init: function() {
        this.setLocale(YupLocale)
    }
};

