import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import Landing from './views/landing';

class App extends Component {
  render() {
    return (
        <Router basename={"/" + document.location.pathname.split('/').filter(Boolean).join("/")}>
          <div>
          <Route exact path="/" component={ Landing } />
          <Route path="/landing" component={ Landing } />
          <Route path="/privacy" component={ Landing } />
          <Route path="/about" component={ Landing } />
          <Route path="/register" component={ Landing } />
          <Route path="/login" component={ Landing } />
          <Route path="/contact" component={ Landing } />
          </div>
        </Router>
    );
  }
}

export default App;
