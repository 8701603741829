import React from 'react';

export default function(props)
{
    return<main className="landing-content">
        <div className="container">
            <section className="row">
                <div className="col-12">
                    <h1 className="text-center">Privacy Policy</h1>
                    <p className="py-3"><br/><br/>Polityka prywatności dotycząca danych rejestracyjnych w Programie Partnerskim <i>/Twoja firma/</i> na <em>Incentive Heroes CRM & Marketing Platform</em> oraz używanych plików „cookies”.</p>
                    <h2 className="text-center my-5">Przetwarzanie danych osobowych</h2>
                    <h3 className="mt-2 mb-1">Informacje ogólne</h3>
                    <p>Właścicielem i operatorem platformy internetowej (dalej „Incentive Heroes CRM & Marketing Platform”) dostępnej pod adresem internetowym <a href="https://crm.incentive-heroes.com">https://crm.incentive-heroes.com</a>, jest Paweł Wojtyszyn prowadzący działalność gospodarczą pod firmą Wagabundo Travel Paweł Wojtyszyn (dalej „Wagabundo Travel”), z siedzibą we Wrocławiu przy ulicy Karpackiej 23A/5, kod pocztowy numer 54-617, zarejestrowany podatnik podatku od towarów i usług, posiadający numer identyfikacji podatkowej NIP 8992227980 oraz numer REGON 021436793, występujący w Rejestrze Organizatorów i Pośredników Turystycznych Województwa Dolnośląskiego pod numerem 546/12/2013.</p>
                    <p className="mt-4 mb-0">Administratorem, czyli podmiotem decydującym o tym, jak będą wykorzystywane Twoje dane osobowe, jest:</p>
                    <ol type="1">
                        <li>Wagabundo Travel, oraz</li>
                        <li><i>/Twoja firma/</i></li>
                        <br/>
                    </ol>
                    <h3 className="mt-5 mb-1">Czy Twoje dane osobowe są bezpieczne?</h3>
                    <p>Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. Stosujemy odpowiednie środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych adekwatne do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpieczamy dane osobowe przed udostępnieniem ich osobom nieupoważnionym, utratą czy uszkodzeniem, dlatego m.in.:</p>
                    <ol type="a">
                        <li>wszelkie dane osobowe przetwarzamy zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólnym rozporządzeniem o ochronie danych, RODO) oraz innymi powszechnie obowiązującymi przepisami prawa,</li>
                        <li>wszystkie przechowywane informacje traktowane są jako poufne i zabezpieczone przed dostępem niepowołanych osób z wykorzystaniem najnowocześniejszych technik ochrony danych dostarczanych przez laboratorium informatyki śledczej DATASTORM (<a href="https://datastorm.pl">https://datastorm.pl</a>)</li>
                        <br/>
                    </ol>
                    <h3 className="mt-5 mb-1">Jaki jest cel i podstawa prawna przetwarzania Twoich danych osobowych?</h3>
                    <p>Dane osobowe przekazywane nam w formularzu rejestracyjnym są przez nas przetwarzane na podstawie udzielonej przez Ciebie zgody i uzasadnionego interesu Administratora, polegającego na weryfikacji spełnienia przez Ciebie warunków udziału (dalej „zgłoszenia”) w Programie Partnerskim <i>/Twoja firma/</i>, w którym mogą brać udział wyłącznie podmioty wskazane przez <i>/Twoja firma/</i>.<br/><br/>Jeśli się zgodzisz, przetworzymy Twoje dane osobowe i zachowamy, w celu: </p>
                    <ol type="a">
                        <li>wynikającego z funkcji konkretnego formularza, tj. pozyskania danych i dokonania na ich podstawie weryfikacji zgłoszenia,</li>
                        <li>skontaktowania się z Tobą poprzez e-mail, telefon i tym samym potwierdzenia poprawności podanych przez Ciebie danych lub dokonania ich korekty,</li>
                        <li>zapisania na używanych przez Ciebie, podczas dokonywania zgłoszenia, urządzeniach końcowych, plików cookie (tzw. ciasteczek),</li>
                        <li>gromadzenia logów serwera www przez operatora hostingowego, w tym informacji o parametrach połączenia (oznaczenie czasu, adres IP).</li>
                        <br/>
                    </ol>
                    <h3 className="mt-5 mb-1">Czy musisz podać nam swoje dane osobowe?</h3>
                    <p>Podanie danych jest dobrowolne, ale niezbędne do celów weryfikacji Twojego zgłoszenia. W tym celu wymagamy podania przez Ciebie:</p>
                    <ol className="mt-0 mb-4" type="a">
                        <li>w przypadku osoby fizycznej: adresu e-mail, imienia i nazwiska, numeru identyfikacji podatkowej NIP, nazwy firmy, adresu stałego wykonywania działalności gospodarczej, kontaktowego numeru telefonu,</li>
                        <li>w przypadku osoby prawnej: adresu e-mail, imienia i nazwiska reprezentanta, numeru identyfikacji podatkowej NIP, nazwy firmy, adresu firmy, kontaktowego numeru telefonu.</li>
                    </ol>
                    <p>Udzielona zgoda może być w każdym czasie cofnięta bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Jak długo przechowujemy Twoje dane osobowe?</h3>
                    <p>Dane przekazane nam w formularzu rejestracyjnym będziemy przechowywać przez okres 12 miesięcy.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Czy możemy udostępnić Twoje dane osobowe?</h3>
                    <p>Przekazane nam przez Ciebie dane podlegają udostępnieniu lub powierzeniu podmiotom zewnętrznym wyłącznie w granicach prawnie dozwolonych, m.in.</p>
                    <ol type="a">
                        <li>odbiorcom, którzy będą posiadać stosowne upoważnienia w związku ze świadczeniem na naszą (Administratora) rzecz usług niezbędnych do realizacji celu przetwarzania, w tym usług informatycznych, lub</li>
                        <li>upoważnionym organom na podstawie zgodnych z prawem żądań w zakresie wynikającym z takich żądań.</li>
                        <br/>
                    </ol>
                    <h3 className="mt-5 mb-1">Czy możemy udostępnić Twoje dane osobowe?</h3>
                    <p>W stosunku do wszystkich przekazanych nam danych osobowych masz prawo żądania dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, sprzeciwu, a także prawo do przenoszenia tych danych w rozumieniu RODO. Masz również prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeżeli uznasz, że doszło do naruszenia ochrony danych.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Jak się z nami skontaktować, żeby uzyskać więcej informacji o przetwarzaniu Twoich danych osobowych?</h3>
                    <p className="mb-5">Napisz do wyznaczonego przez nas administratora danych osobowych. Oto jego dane kontaktowe: adres e-mail: <a href="mailto:biuro@wagabundotravel.pl?subject=PolitykaPrywatnosci">biuro@wagabundotravel.pl</a>, adres pocztowy: Wagabundo Travel Paweł Wojtyszyn, Karpacka 23A/5, 54-617 Wrocław z adnotacją „Administrator Danych Osobowych”.<br/><br/></p>
                    <div className="d-none d-md-block section-spacer"/>
                    <h2 className="text-center mt-5 mb-5">Polityka Cookies</h2>
                    <h3>Informacja ogólna o plikach „cookies”</h3>
                    <p>Na naszej stronie internetowej Incentive Heroes CRM & Marketing Platform stosujemy pliki „cookies”. Korzystając z niej, bez zmiany ustawień przeglądarki, wyrażasz zgodę na stosowanie plików „cookies” zgodnie z niniejszą Polityką Prywatności, z zachowaniem prawa do samodzielnego określenia warunków ich przechowywania lub dostępu do nich poprzez zmianę ustawień „cookies” w swojej przeglądarce.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Czym są pliki „cookies”?</h3>
                    <p>Pliki „cookies” to krótkie pliki tekstowe, które są przechowywane w Twojej przeglądarce lub na Twoim dysku twardym komputera, jako użytkownika Incentive Heroes CRM & Marketing Platform. W przypadku Twojej ponownej wizyty na naszej stronie internetowej pliki „cookies” pozwalają nam rozpoznać Twoje urządzenie i dostosować stronę internetową do Twoich preferencji.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Jakie funkcje pełnią pliki „cookies”?</h3>
                    <p>Pliki „cookies” mogą pełnić różne funkcje. Podstawowe z nich to zapamiętywanie Twoich preferencji i dostosowywanie do nich zawartości naszej strony internetowej Incentive Heroes CRM & Marketing Platform, umożliwienie przygotowania statystyk odwiedzin naszej strony internetowej lub możliwość polecenia Ci treści, która będzie dla Ciebie najbardziej odpowiednia.<br/><br/></p>
                    <h3 className="mt-5 mb-1">W jakim celu stosujemy pliki „cookies”?</h3>
                    <ol className="mt-0" type="a">
                        <li>konfiguracji Incentive Heroes CRM & Marketing Platform - umożliwiają nam ustawienia funkcji i usług na platformie,</li>
                        <li>uwierzytelniania - umożliwiają nam informowanie, gdy jesteś zalogowany, dzięki czemu witryna może pokazywać Ci odpowiednie informacje i funkcje,</li>
                        <li>reklamy - umożliwiają nam wyświetlanie reklam, które są bardziej interesujące dla Ciebie, a także personalizowanie tych reklam,</li>
                        <li>analizy i badania Twoich zachowań - umożliwiają nam odczytanie Twoich preferencji i poprzez ich analizę ulepszanie i rozwijanie produktów i usług. Zbieranie informacji odbywa się anonimowo, bez identyfikowania Twoich danych osobowych, chyba że dokonasz logowania w naszym Panelu Incentive Heroes CRM & Marketing Platform, a ustawienia Twojego urządzenia końcowego nam na to pozwolą.</li>
                        <br/>
                    </ol>
                    <h3 className="mt-5 mb-1">Jakich plików „cookies” używamy?</h3>
                    <p>Stosujemy kilka rodzajów plików „cookies”, które wyróżniamy ze względu na następujące kryteria:</p>
                    <ol type="a">
                        <li>„sesyjne” – które przechowywane są w Twoim urządzeniu końcowym do czasu wylogowania, opuszczenia Incentive Heroes CRM & Marketing Platform lub wyłączenia przeglądarki internetowej,</li>
                        <li>„stałe” – przechowywane w Twoim urządzeniu końcowym przez czas określony w parametrach plików „cookies” lub do czasu ich usunięcia przez Ciebie,</li>
                        <li>„wydajnościowe” umożliwiające zbieranie informacji o sposobie korzystania przez Ciebie z Incentive Heroes CRM & Marketing Platform,</li>
                        <li>„niezbędne” – umożliwiające korzystanie przez Ciebie z usług dostępnych w Incentive Heroes CRM & Marketing Platform,</li>
                        <li>„funkcjonalne” umożliwiające zapamiętanie wybranych przez Ciebie ustawień i personalizację interfejsu Użytkownika w Incentive Heroes CRM & Marketing Platform</li>
                        <li>„własne” zamieszczane przez Incentive Heroes CRM & Marketing Platform,</li>
                        <li>„zewnętrzne” pochodzące z poza Incentive Heroes CRM & Marketing Platform, np. pochodzące od podmiotów, z którymi współpracujemy, takich jak: Google, Facebook, oraz od innych podmiotów realizujących dla nas kampanie reklamowe. Z uwagi na fakt, że sposób działania niektórych plików „cookies” pochodzących od partnerów zewnętrznych może być inny, niż przedstawiony w niniejszej polityce, prosimy Cię o zapoznanie się z informacjami na temat tych plików „cookies” dostępnymi na stronach internetowych naszych partnerów:</li>
                        <ul>
                        <li>Google: <a href="https://www.google.pl/intl/pl/policies/technologies/cookies/">https://www.google.pl/intl/pl/policies/technologies/cookies/</a></li>
                        <li>Facebook: <a href="https://www.facebook.com/help/cookies/">https://www.facebook.com/help/cookies/</a></li>
                        </ul>
                        <br/>
                    </ol>
                    <h3 className="mt-5 mb-1">Jak możesz usunąć lub zablokować pliki „cookies”?</h3>
                    <p>Domyślne ustawienia przeglądarki internetowej zazwyczaj pozwalają na umieszczanie plików „cookies” w Twoim urządzeniu końcowym. Zgodnie z art. 173 ust. 2 ustawy Prawo telekomunikacyjne (Dz.U. 2017 poz. 1907) pozostawienie takich ustawień przeglądarki po otrzymaniu informacji na temat plików „cookies” stosowanych w witrynie jest równoznaczne z wyrażeniem przez Ciebie zgody na stosowanie plików „cookies” w witrynach internetowych. Jako użytkownik możesz zmienić domyślne ustawienia przeglądarki w celu uniknięcia zamieszczenia plików „cookies” na swoim urządzeniu, całkowicie je usunąć lub zablokować. Szczegółowe informacje na ten temat znajdziesz w pomocy lub dokumentacji stosowanej przez siebie przeglądarki internetowej. Pamiętaj jednak, że usunięcie lub blokada plików „cookies” może wiązać się z tym, że niektóre funkcje Incentive Heroes CRM & Marketing Platform nie będą działały poprawnie.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Gromadzenie innych danych</h3>
                    <p>W Incentive Heroes CRM & Marketing Platform, przechowujemy także inne dane niż te, które pozyskujemy z wykorzystaniem plików „cookies”. Te dane to przede wszystkim adresy IP, czas nadejścia zapytania do naszego serwera, informacje o typie i wersji systemu operacyjnego oraz przeglądarki, których używasz. Ponadto, jeśli zostaniesz przekierowany na stronę internetową Incentive Heroes CRM & Marketing Platform z innej strony internetowej, to zbieramy informację o adresie URL, z którego nastąpiło przekierowanie.<br/><br/></p>
                    <h3 className="mt-5 mb-1">Wykorzystywanie danych</h3>
                    <p>Zebrane dane przechowujemy przede wszystkim w celu zapewnienia możliwie najwyższej jakości administrowania platformą Incentive Heroes CRM & Marketing Platform. Ponadto służą nam one do sporządzania statystyk dotyczących sposobu korzystania przez Ciebie z naszej platformy. Dane gromadzone przy użyciu plików „cookies” nie są ujawniane osobom trzecim, za wyjątkiem upoważnionych przepisami prawa organów władzy publicznej.<br/><br/></p>
                </div>
            </section>
        </div>
    </main>;
}
