import WeDifferImg from "../assets/landing/color_logo_tinny.svg";
import Img2 from "../assets/landing/img2.jpg";
import Img3 from "../assets/landing/img6.jpg";
import React from "react";
import posed from 'react-pose';

const childprops = {
    enter: {
        opacity: 1,
        x: 0
    },
    exit: {
        opacity: 0,
        x: 100
    }
};

const H1 = posed.h1(childprops);
const H2 = posed.h2(childprops);
const H3 = posed.h3(childprops);
const P = posed.p(childprops);
const PosedImg = posed.img(childprops);
const PosedBtn = posed.button(Object.assign({}, childprops, {
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 }
}));

const PosedDiv = posed.div({
    enter: {
        staggerChildren: 50
    }
});

class LandingContent extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            mounted: false
        };
    }

    componentDidMount() {
        this.setState({mounted: true});
    }

    render() {
        const props = this.props;

        return <main className="landing-content">
            <PosedDiv pose={ this.state.mounted ? 'enter':'exit' } className="container">
                <div className="row fullscreen align-items-center">
                    <div className="col-12 col-md-4 d-flex align-items-center justify-content-center mb-5">
                        <PosedImg src={WeDifferImg} alt="We differ only in colors" className="img-fluid rounded-circle"/>
                    </div>
                    <div className="col-12 col-md-8 col-lg-7 offset-lg-1">
                        <div className="cta">
                            <H2 className="mb-0 pb-2">Incentive Project</H2>
                            <H1>Thailand <em>2019</em></H1>
                            <H3>Partner program</H3>
                            <P className="mt-4 mb-5">
                                Test new ideas. Inspire your imagination. Awake ambitions.
                                Set and overcome the boundaries. Real, personal and business ones.
                                Earn rewards. Write own story. Be a Hero.
                            </P>
                            <PosedBtn className="btn btn-outline-light mr-3" onClick={props.onAboutClick}>MORE ABOUT</PosedBtn>
                            <PosedBtn className="btn btn-primary" onClick={props.onJoinClick}>JOIN US NOW</PosedBtn>
                        </div>
                    </div>
                </div>
                <div style={{height: "3rem"}} ref={props.secondRef}/>
                <section className="row">
                    <div className="col-12 col-md-4 d-flex align-content-top justify-content-center mb-5">
                        <div>
                            <div className="border rounded-circle position-sticky">
                                <img src={Img2} alt="We differ only in colors"
                                     className="img-fluid rounded-circle border"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-7 offset-lg-1">
                        <h1>Partner program</h1>
                        <p className="display-5 py-3">Project <em>Thailand 2019</em></p>

                        <h2>Sign in and open your mind to new challenges!</h2>
                        <h4 className="mb-5">Fill the form. Read and accept the Terms, Conditions and Privacy Policy. After verification your account will be activated by us as soon is possible.</h4>

                        <h3>Join to others and take part in our competitions!</h3>
                        <p className="mb-5">Gain motivation and inspiration to act!</p>

                        <h3>Get your rewards!</h3>
                        <p className="mb-5">Printers, projectors and other surprises await you.</p>

                        <h4>Become one of twenty Winners and go for the adventure of your life, during which you’ll:</h4>
                        <ul className="mb-5">
                            <li>build long-term and satisfying relationships</li>
                            <li>gain new experiences</li>
                            <li>set and overcome your boundaries</li>
                            <li>defeat your weakness</li>
                            <li>taste amazing dishes</li>
                        </ul>

                        <h2>Because we achieve our maximum potential, being around people that complement us, and whom we complement equally well!</h2>

                        <div className="cta">
                            <button className="btn btn-primary mt-4" onClick={props.onJoinClick}>JOIN US NOW!</button>
                        </div>
                    </div>
                </section>

                <section className="row">
                    <div className="col-12 col-md-4 d-flex align-content-top justify-content-center mb-5 mt-5">
                        <div>
                            <div className="border rounded-circle position-sticky">
                                <img src={Img3} alt="We differ only in colors"
                                     className="img-fluid rounded-circle border"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 col-lg-7 offset-lg-1 mb-5">
                        <div className="d-none d-md-block section-spacer"/>
                        <h1 className="mb-5">Main prize</h1>
                        <h3>7-day adventure of your life in Thailand, when you will:</h3>
                        <ul className="mb-5">
                            <li>sail on an evening cruise on the Menam - the basin of Bangkok</li>
                            <li>see the most famous monuments of Bangkok, including Wat Arun (Temple of Dawn), Wat Pho (Temples of the Reclining Buddha), Wat Phra (Temples of the Emerald Buddha), Grand Royal Palace</li>
                            <li>spend an unforgettable night visiting the sky-high drink bars located on the tallest skyscrapers in Bangkok</li>
                            <li>go on a cycling culinary journey, during which you will be able to prepare and compose dishes yourself, accompanied by the most eminent local chefs</li>
                            <li>check if your Personality is bigger than your Ego, during helping local volunteers with orphaned elephants</li>
                            <li>check yourself at Thai boxing</li>
                            <li>.. and at the end, you will rest on one of the paradise beaches.</li>
                        </ul>
                        <div className="cta">
                            <button className="btn btn-outline-primary mt-4" onClick={props.onJoinClick}>SIGN UP TODAY!</button>
                        </div>
                    </div>
                </section>
            </PosedDiv>
        </main>;
    }
}

export default LandingContent;
